<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form>
            <el-form-item label="分佣金额显示">
              <el-switch v-model="form.goods_award_base_fee"></el-switch>
              分佣金额单位
              <el-input v-model="form.goods_award_base_fee_unit" style="width: 10rem"></el-input>
            </el-form-item>
            <el-form-item label="客服二维码">
              <y_upload_img v-model="form.waiter_qrcode"></y_upload_img>
            </el-form-item>
            <el-form-item label="首页分享图片">
              <y_upload_img v-model="form.index_share_pic"></y_upload_img>
            </el-form-item>
            <el-form-item label="首页分享标题">
              <el-input v-model="form.index_share_title"></el-input>
            </el-form-item>
            <el-form-item label="首页分享描述">
              <el-input v-model="form.index_share_desc"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form>
            <el-form-item label="商品采集接口API KEY">
              <el-input v-model="form.import_goods_api_key"></el-input>
            </el-form-item>
            <el-form-item label="物流查询接口用户ID">
              <el-input v-model="form.express_appid"></el-input>
            </el-form-item>
            <el-form-item label="物流查询接口API key">
              <el-input v-model="form.express_app_key"></el-input>
            </el-form-item>
            <el-form-item label="海报文案">
              <el-input v-model="form.poster_copy_writing" type="textarea" autosize></el-input>
              <div class="y-desc">每行一条，回车换行</div>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";

export default {
  name: "info",
  components: {
    y_upload_img,
  },
  data() {
    return {
      form: {
        goods_award_base_fee: false,
        goods_award_base_fee_unit: "",
        import_goods_api_key: "",
        express_appid: "",
        express_app_key: "",
        index_share_title: "",
        index_share_pic: "",
        index_share_desc: "",
        waiter_qrcode: "",
        poster_copy_writing: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.sys.info().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.shop.sys.infoEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>